import { unstable_batchedUpdates } from "react-dom";
import { useChartStore } from "../stores/chartStore";

const devURL = "http://localhost:8000";
const prodURL = "https://server.cubeslabs.xyz";

class Server {
  endpoint = process.env.NODE_ENV === "development" ? devURL : prodURL;

  async get(url: string) {
    const data = await fetch(url)
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data;
  }

  async getChartData() {
    const data = await this.get(`${this.endpoint}/chart`);

    if (!data?.minedCount) return console.log("No data");

    unstable_batchedUpdates(() => {
      useChartStore.getState().setData(data);
      return data;
    });
  }

  async getActiveUsers(type: 'day'|'week'|'month'|'all') {
    const data = await this.get(`${this.endpoint}/chart/active?type=${type}`);

    if (!data?.users) return console.log("No data");
    
    unstable_batchedUpdates(() => {
      useChartStore.getState().setActiveUsersData(data.users);
      return data;
    });
  }
}

export const server = new Server();
